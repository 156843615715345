import { request } from '@/utils/request'

export function getAssetAccountList () {
  return request.get('/asset_account')
}

export function getAssetAccountDetail (id) {
  return request.get(`/asset_account/${id}`)
}

export function getAssetAccountSeriesData (id, params = {}) {
  return request.get(`/asset_account/${id}/series`, { params })
}

export function getAssetAccountData (id, params = {}) {
  return request.get(`/asset_account/${id}/data`, { params })
}

export function getAssetAccountSymbol (id, params = {}) {
  return request.get(`/asset_account/${id}/symbol`, { params })
}

export function getAssetAccountTrade (id, params = {}) {
  return request.get(`/asset_account/${id}/trade`, { params })
}
