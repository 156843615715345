<template>
  <div class="assets">
    <div class="assets-wrapper">
      <div class="banner">
        <div class="banner-bg"></div>
        <div class="banner-description">
          <b-container class="banner-description-container">
            <h2>账户资产</h2>
            <p style="margin-top: 2rem;max-width: 768px">实时监控账户资产变动，第一时间掌握盈亏趋势</p>
          </b-container>
        </div>
        <div class="banner-overlay"></div>
      </div>
      <div class="container table-container">
        <b-card>
          <b-table @row-clicked="handleRowClicked" :responsive="true" sort-icon-left hover :items="items" :fields="fields" borderless>
            <template v-slot:cell(fund_name)="data">
              <b>{{ data.value }}</b>
            </template>
            <template v-slot:cell(capital)="data">
              <div class="flex">{{ data.value | toFixed }}
                <data-transition percentage :value="Number(data.value)"/>
              </div>
            </template>
            <template v-slot:cell(net_value)="data">
              <div class="flex">{{ data.value | toFixed }}
                <data-transition percentage :value="Number(data.value)"/>
              </div>
            </template>
            <template v-slot:cell(daily_return)="data">
              <div class="flex">{{ data.value | toPercent }}
                <data-transition percentage :value="Number(data.value)"/>
              </div>
            </template>
            <template v-slot:cell(max_drawdown)="data">
              <div class="flex">{{ data.value || 0 | toPercent }}
                <data-transition percentage :value="Number(data.value)"/>
              </div>
            </template>
            <template v-slot:cell(annualized_return_ratio)="data">
              <div class="flex">{{ data.value | toPercent }}
                <data-transition percentage :value="Number(data.value)"/>
              </div>
            </template>
          </b-table>

        </b-card>
      </div>
    </div>
    <Footer class="grade-footer"/>
  </div>
</template>

<script>
import DataTransition from '@/components/DataTransition'
import { toPercent, toScalableFixed } from '@/utils/math'
import Footer from '@/layout/Footer'
import { getAssetAccountList } from '@/api/assets'
import { mapState } from 'vuex'

export default {
  name: 'Grade',
  components: {
    Footer,
    DataTransition,
  },
  data () {
    return {
      /**
       * @type WebSocket|null
       */
      ws: null,
      fields: [
        {
          key: 'name',
          sortable: false,
          label: '账户名称',
        },
        {
          key: 'capital',
          sortable: true,
          label: '资金(USDT)',
        },
        {
          key: 'net_value',
          sortable: true,
          label: '净值',
        },
        {
          key: 'daily_return',
          sortable: true,
          label: '日收益',
        },
        {
          key: 'max_drawdown',
          sortable: true,
          label: '最大回撤',
        },
        {
          key: 'annualized_return_ratio',
          sortable: true,
          label: '年化收益',
        },
      ],
      items: [],
    }
  },
  computed: {
    ...mapState({
      access: 'access',
    }),
  },
  filters: {
    toFixed (n) {
      return toScalableFixed(n, 4, true)
    },
    toPercent,
  },
  created () {
    if (this.$root.wsOpened) {
      this.login()
    } else {
      this.$root.$on('ws-open', () => {
        this.login()
      })
    }

    this.$root.$on('ws-message', (data) => {
      if (data.type === 'login') {
        this.fetchAssetAccountList()
      } else if (data.type === 'fund/asset') {
        const itemIndex = this.items.findIndex(item => item.id === data.data.account?.id)
        if (itemIndex !== -1) {
          this.items.splice(itemIndex, 1, {
            ...this.items[itemIndex],
            ...data.data,
            id: this.items[itemIndex]?.id,
          })
        }
      }
    })
  },
  beforeDestroy () {
    if (this.$root.ws) {
      this.$root.ws.unsubscribe(this.items.map(r => `fund/asset:${r.id}`))
    }
  },
  methods: {
    percentChangeFormatter (value, diff) {
      return toPercent(diff)
    },
    handleRowClicked (item, index, event) {
      this.$router.push({
        name: 'AssetAccountDetail',
        query: { id: item.id },
      })
    },
    login () {
      this.$root.ws.sendJson({
        action: 'login',
        params: this.access,
      })
    },
    fetchAssetAccountList () {
      return getAssetAccountList().then(res => {
        this.items = res.data.results
        this.$root.ws.subscribe(res.data.results.map(r => `fund/asset:${r.id}`))
      })
    },
  },
}
</script>

<style lang="less" scoped>
.assets {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  padding-top: 76px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.banner {
  position: relative;
  background: url("../assets/banner-grade.jpg") bottom right / auto 100% no-repeat;
  transition: all 0.5s ease-out;

  &:hover {
    background-size: auto 105%;
  }
}

.banner-description {
  position: relative;
  background: url("../assets/banner-cover.png") bottom left / 100% no-repeat;
  z-index: 1;
  color: #ffffff;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 100px;

  @media (max-width: 768px) {
    background: none;
    padding-bottom: 0;
  }
}

.banner-description-container {
  padding-top: 100px;
  padding-bottom: 100px;

  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.banner-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: .9;
  background: linear-gradient(-29deg, #616d86, #1f1c2c);
}

.grade-table-container {

}

.table-container {
  margin-top: 40px;
  width: 100%;
  font-size: 12px;
}

.grade-footer {
  margin-top: 250px;
}
</style>
